function number_format(number, decimals, dec_point, thousands_sep) {
	// *     example: number_format(1234.56, 2, ',', ' ');
	// *     return: '1 234,56'
	number = (number + '').replace(',', '').replace(' ', '');
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
		dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		s = '',
		toFixedFix = function(n, prec) {
			var k = Math.pow(10, prec);
			return '' + Math.round(n * k) / k;
		};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}
	return s.join(dec);
}

function ordinal( number ) {
	if ( number % 100 == 11 ) {
		return "11th"
	}

	if ( number % 100  == 12 ) {
		return "12th"
	}

	if ( number % 100  == 13 ) {
		return "13th"
	}

	if ( number % 10 == 1 ) {
		return number + "st"
	}

	if ( number % 10 == 2 ) {
		return number + "nd"
	}

	if ( number % 10 == 3 ) {
		return number + "rd"
	}

	return number + "th"
}

function format_time( time ) {
	var original_time = time;
	time = Math.abs( time );

	return ( original_time > 0 ? "" : "" ) + Math.floor( time / 60 ) + "m" + ( time % 60 ).toString().padStart( 2, '0' );
}

window.number_format = number_format;
window.ordinal = ordinal;
window.format_time = format_time;
