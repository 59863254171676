// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("channels")

import "@fortawesome/fontawesome-free/js/all";
import './bootstrap_custom.js'
import './jquery.easing.js'
import './sb-admin-2.js'
import './Chart.js'
import './chart_callbacks.js'
import './formatters.js'

import SegmentList from './SegmentList.js'
window.SegmentList = SegmentList;

import '../stylesheets/application.scss'


function tile_to_lat_long( x, y ) {
	var n = Math.pow( 2, 14 );
	var lon_deg = ( x / n * 360.0 ) - 180.0;
	var lat_rad = Math.atan( Math.sinh( Math.PI * (1.0 - ( 2.0 * y / n ) ) ) );
	var lat_deg = lat_rad * 180.0 / Math.PI;

	return [ lat_deg, lon_deg ];
}

window.tile_to_lat_long = tile_to_lat_long;



// Set new default font family and font color to mimic Bootstrap's default styling
Chart.defaults.global.defaultFontFamily = 'Nunito,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
Chart.defaults.global.defaultFontColor = '#000';