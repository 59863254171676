function eddingtonCustomPointRadiusCallback( context ) {
	// the data is 0-indexed, so when dataIndex = 0, it means miles = 1
	let index = context.dataIndex;
	let value = context.dataset.data[ index ];
	let next_value = context.dataset.data[ index + 1];

	if ( value >= index && next_value < index + 1) {
		return 5;
	}

	return 0;
}

function eddingtonCustomPointHoverRadiusCallback( context ) {
	// the data is 0-indexed, so when dataIndex = 0, it means miles = 1
	let index = context.dataIndex;
	let value = context.dataset.data[ index ];
	let next_value = context.dataset.data[ index + 1];

	if ( value >= index && next_value < index + 1) {
		return 8;
	}

	return 3;
}

function eddingtonTooltipCallback( context, chart ) {
	var datasetLabel = chart.datasets[context.datasetIndex].label || '';
	return datasetLabel + ': ' + number_format(context.yLabel) + " rides of at least " + context.label + " miles"
}

function explorerScoreTooltipCallback( context, chart ) {
	var datasetLabel = chart.datasets[context.datasetIndex].label || '';
	return datasetLabel + ': ' + number_format(context.yLabel) + " squares"
}

window.eddingtonCustomPointRadiusCallback = eddingtonCustomPointRadiusCallback;
window.eddingtonCustomPointHoverRadiusCallback = eddingtonCustomPointHoverRadiusCallback;
window.eddingtonTooltipCallback = eddingtonTooltipCallback;
window.explorerScoreTooltipCallback = explorerScoreTooltipCallback;