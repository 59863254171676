export default class SegmentList {
	// segment_ids = default sort order

	constructor( segment_html_dom_id, segment_details_panel_dom_id, segment_effort_data, segment_data, timing_data, athlete_data, signed_in_athlete_id, extra_text = "" ) {
		this.segment_effort_data = segment_effort_data;
		this.segment_html_dom_id = segment_html_dom_id;
		this.segment_data = segment_data;
		this.timing_data = timing_data;
		this.athlete_data = athlete_data;
		this.signed_in_athlete_id = signed_in_athlete_id;
		this.segment_details_panel_dom_id = segment_details_panel_dom_id;
		this.extra_text = extra_text;

		this.full_segment_effort_data = this.segment_effort_data && this.segment_effort_data[0] && ( this.segment_effort_data[0].constructor == Object );
	}

	static find_segment_effort_time_difference( athlete_data, segment_effort_time ) {
		let top_time = athlete_data[ 0 ][ "times" ][ 0 ][ "elapsed_time" ];
		let difference = null;

		// The effort time can't be better than the top time!
		if ( segment_effort_time == top_time ) {
			return 0;
		} else {
			return segment_effort_time - top_time;
		}
	}

	static format_segment_effort_time_difference( time_difference ) {
		let icon = null;
		let span_class = null;

		console.log( time_difference );

		if ( time_difference == 0 ) {
		} else {
			span_class = "time-down";
			icon = "<i class='fa fa-fw fa-arrow-down'></i>";
		}

		return 
	}

	static find_time_difference( athlete_data, look_for_athlete_id ) {
		let place = SegmentList.find_place( athlete_data, look_for_athlete_id );

		if ( athlete_data.length == 1 ) {
			return format_time( athlete_data[ 0 ][ "times" ][ 0 ][ "elapsed_time" ] );
		} else {
			let yours = null;
			let other = null;

			if ( place == 1 ) {
				yours = athlete_data[ 0 ][ "times" ][ 0 ][ "elapsed_time" ];
				other = athlete_data[ 1 ][ "times" ][ 0 ][ "elapsed_time" ];
			}	else if ( place == 2 ) {
				other = athlete_data[ 0 ][ "times" ][ 0 ][ "elapsed_time" ];
				yours = athlete_data[ 1 ][ "times" ][ 0 ][ "elapsed_time" ];
			} else {
				// "XmXX behind first place"
				other = athlete_data[ 0 ][ "times" ][ 0 ][ "elapsed_time" ];
				yours = athlete_data[ place - 1 ][ "times" ][ 0 ][ "elapsed_time" ];
			}

			return yours - other;
		}
	}

	static format_time_difference( time_difference ) {
		let icon = null;
		let span_class = null;

		if ( time_difference > 0 ) {
			span_class = "time-down";
			icon = "<i class='fa fa-fw fa-arrow-down'></i>";
		} else if ( time_difference < 0 ) {
			span_class = "time-up";
			icon = "<i class='fa fa-fw fa-arrow-up'></i>";
		} else {
			span_class = "time-equal";
			icon = "<i class='fa fa-fw fa-equals'></i>";
		}

		return "<span class='" + span_class + "'>" + format_time( time_difference ) + " " + icon + "</span>";
	}

	static find_place( athlete_data, look_for_athlete_id ) {
		var place = 0;

		if ( athlete_data.length == 1 ) {
			return place;
		}

		jQuery.each( athlete_data, function( index, data ) {
			if ( data[ "athlete_id" ] == look_for_athlete_id ) {
				place = index + 1;
			}
		});

		return place;
	}


	update_list() {
		var matching_segment_ids = [];
		var athletes = [];
		var scores = {}
		var segment_list = []

		//
		//  Get the athletes which are chosen to have to have a time
		//
		$( ".athlete-toggle.btn-success" ).each( function() {
			athletes.push( $(this).attr( "data-athlete-id" ) );
		})
		athletes.sort();


		let segment_list_class = this;

		//
		//  If some activity data is provided, then we want the default order
		//  of the table we produce to be in the same order.  Else, behaviour
		//  is undefined, and it's up to the user to provide a sort order
		//  for the DataTable
		//
		if ( this.segment_effort_data ) {
			jQuery.each( this.segment_effort_data, function( index, segment_effort_data ) {
				let times = [];
				let segment_id = null;

				if ( segment_list_class.full_segment_effort_data ) {
					times = segment_list_class.timing_data[ segment_effort_data.segment_id ];
					segment_id = segment_effort_data.segment_id;
				} else {
					times = segment_list_class.timing_data[ segment_effort_data ];
					segment_id = segment_effort_data;
				}

				let athletes_with_times = Object.keys( times ).sort();
				let found = true;

				jQuery.each( athletes, function( index, athlete_id ) {
					if ( athletes_with_times.indexOf( athlete_id ) == -1 ) {
						found = false;
					}
				});

				if ( found == true ) {
					matching_segment_ids.push( segment_id );
				}
			});

		} else {
			jQuery.each( this.timing_data, function( segment_id, times ) {
				let athletes_with_times = Object.keys( times ).sort();
				let found = true;
	
				jQuery.each( athletes, function( index, athlete_id ) {
					if ( athletes_with_times.indexOf( athlete_id ) == -1 ) {
						found = false;
					}
				});
	
				if ( found == true ) {
					matching_segment_ids.push( segment_id );
				}
			});
	
		}

		//
		//  Rejig the data structure, and sort by the best times.
		//
		jQuery.each( matching_segment_ids, function( i, segment_id ) {
			var athlete_collection = []

			jQuery.each( segment_list_class.timing_data[ segment_id ], function( athlete_id, times ) {
				var athlete = { "athlete_id": athlete_id, "times": [] };

				jQuery.each( times, function( index, time ) {
					athlete[ "times" ].push( time );
				});

				athlete_collection.push( athlete );
			});

			athlete_collection.sort( function( a, b ) {
				if ( a[ "times" ][0][ "elapsed_time" ] < b[ "times" ][0][ "elapsed_time" ] ) {
					return -1;
				}

				if ( a[ "times" ][0][ "elapsed_time" ] > b[ "times" ][0][ "elapsed_time" ] ) {
					return 1;
				}

				if ( a[ "times" ][0][ "start_date" ] < b[ "times" ][0][ "start_date" ] ) {
					return -1;
				}

				if ( a[ "times" ][0][ "start_date" ] > b[ "times" ][0][ "start_date" ] ) {
					return 1;
				}

				if ( a[ "times" ][0][ "strava_activity_id" ] < b[ "times" ][0][ "strava_activity_id" ] ) {
					return -1;
				}

				if ( a[ "times" ][0][ "strava_activity_id" ] > b[ "times" ][0][ "strava_activity_id" ] ) {
					return 1;
				}

				return 0;
			});

			segment_list.push( { "segment_id": segment_id,
													 "athlete_data": athlete_collection } );
		});


		//
		//  Read the placing filter
		//
		var placing_filter_athlete_id = null;
		var placing_filter_athlete_place = null;

		placing_filter_athlete_id = $( "#athlete-placing-filter-name" ).children( "option:selected" ).val();
		placing_filter_athlete_place = $( "#athlete-placing-filter-place" ).children( "option:selected" ).val();

		//
		// Filter out segments that don't match the place filter
		//
		if ( placing_filter_athlete_id > 0 ) {
			segment_list = jQuery.grep( segment_list, function( segment, i ) {
				if ( segment["athlete_data"].length >= placing_filter_athlete_place ) {
					if ( segment["athlete_data"][ placing_filter_athlete_place - 1 ][ "athlete_id" ] == placing_filter_athlete_id ) {
						return true;
					}
				}

				return false;
			});
		}

		let html = "";
		html += "<thead>";
		html += "  <tr>";
		html += "  	<th>&nbsp;</th>";
		html += "  	<th>&nbsp;</th>";
		html += "  	<th>Segment</th>";
		html += "  	<th style='text-align: center;'>Avg %</th>";
		html += "  	<th style='text-align: center;'>Length</th>";

		if ( this.segment_effort_data && this.full_segment_effort_data ) {
			html += "   <th style='text-align: center;'>Time</th>";
			html += "   <th style='text-align: center;'>PR?</th>";
		} else {
			html += "  	<th>Pos</th>";
		}

		html += "  	<th style='text-align: center;'>vs Twats " + this.extra_text + "</th>";
		html += "  	<th>Top 3 " + this.extra_text + "</th>";
		html += "  </tr>";
		html += "</thead>";
		html += "<tbody>";

		jQuery.each( segment_list, function( i, segment ) {
			let time_difference = "";
			let activity_time = 0; // Should be called segment_effort_time
			let activity_pr_rank = 0; // Should be called segment_effort_pr_rank
			let place = 0;

			//  If we're looking at an activity, the relative time is relative to our top time.
			if ( segment_list_class.segment_effort_data && segment_list_class.full_segment_effort_data ) {
				jQuery.each( segment_list_class.segment_effort_data, function( index, segment_effort_data ) {
					if ( segment_effort_data.segment_id == segment[ "segment_id" ] ) {
						activity_time = segment_effort_data.elapsed_time;
						activity_pr_rank = segment_effort_data.pr_rank;
					}
				});

				time_difference = segment_list_class.constructor.find_segment_effort_time_difference( segment[ "athlete_data" ], activity_time );

			} else {
				place = segment_list_class.constructor.find_place( segment[ "athlete_data" ], segment_list_class.signed_in_athlete_id );

				if ( place ) {
					time_difference = segment_list_class.constructor.find_time_difference( segment[ "athlete_data" ], segment_list_class.signed_in_athlete_id );
				}
	
			}

			let length = segment_list_class.segment_data[ segment[ "segment_id" ] ][ "length" ] / 1609.344;
			let grade = segment_list_class.segment_data[ segment[ "segment_id" ] ][ "average_grade" ];
			let athlete_list = segment[ "athlete_data" ].slice( 0, 3 ).map( function( athlete ) {
				return segment_list_class.athlete_data[ athlete[ "athlete_id" ] ] + ": <a target='strava' href='https://www.strava.com/segment_efforts/" + athlete[ "times" ][0][ "strava_segment_effort_id" ] + "'>" + format_time( athlete[ "times" ][0][ "elapsed_time" ] ) + "</a>";
			}).join( ", " );

			html += "  <tr>";
			html += "    <td style='padding-top: 7px'>";
			html += "      <a style='cursor: pointer' class='segment-reveal no-hover' data-segment-id='" + segment[ "segment_id" ] + "'><i class='fas fa-ellipsis-v fa-sm fa-fw'></i></a>";
			html += "    </td>";
			html += "    <td>";
			html += "      <div class='strava-icon strava-link' data-href='https://www.strava.com/segments/" + segment_list_class.segment_data[ segment[ "segment_id" ] ][ "strava_segment_id" ] + "?filter=club&club_id=626927'></div>";
			html += "    </td>";
			html += "    <td style='padding-top: 7px; max-width: 1000px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>";
			html += "      <span title='Twats Segment ID : " + segment[ "segment_id" ] + "'>" + segment_list_class.segment_data[ segment[ "segment_id" ] ][ "name" ] + "</span>";
			html += "    </td>";
			html += "    <td style='text-align: center; padding-top: 7px'>" + grade.toFixed( 1 ) + "%</td>";
			html += "    <td style='text-align: center; padding-top: 7px'>" + length.toFixed( 1 )  + "mi</td>";

			if ( segment_list_class.segment_effort_data && segment_list_class.full_segment_effort_data ) {
				html += "    <td style='text-align: center; padding-top: 7px'>" + format_time( activity_time )  + "</td>";
			} else {
				html += "    <td><div class='" + ( place ? "position place-" + ordinal ( place ) : "" ) + "'>";
				html +=        ( place ? ordinal( place ) : "N/A" );
				html += "    </div></td>";
			}

			if ( segment_list_class.segment_effort_data && segment_list_class.full_segment_effort_data ) {
				html += "    <td style='padding-top: 5px; padding-bottom: 0; text-align: center'>";
				if ( activity_pr_rank ) {
					html += "    <div style='margin: 0 auto;' class='pr-rank pr-rank-" + ordinal( activity_pr_rank ) + "'>" + ordinal( activity_pr_rank ) + "</div>";
				}
				html += "    </td>"

				html += "    <td style='padding-top: 7px; text-align: center'>";
				if ( time_difference == 0 ) {
					html += "    <div style='margin: 0 auto;' class='position place-1st'>TOM</div>";
				} else {
					html += "    <span class='time-down'>" + format_time( time_difference ) + " <i class='fa fa-fw fa-arrow-down'></i></span>";
				}
				html += "    </td>";
			} else {
				html += "    <td style='padding-top: 7px; text-align: center'>";
				html += 	     ( place ? segment_list_class.constructor.format_time_difference( time_difference ) : "--" );
				html += "    </td>";
			}

			html += "    <td>" + athlete_list + "</td>";
			html += "  </tr>";
		});

		html += "</tbody>";

		$( this.segment_html_dom_id ).html( html );

		this.bind_event_handlers();
	}

	bind_event_handlers() {
		let segment_list_class = this;

		$( this.segment_html_dom_id + " div.strava-icon" ).click( function() {
			window.open( $(this).attr( "data-href" ), "strava" );
		});

		$( this.segment_html_dom_id + " .segment-reveal" ).click( function() {
			event.stopPropagation();

			// Recent = segment id 
			// 6 : = a : position() = 8 : offset() = 1957.6875
			// 6 : pa() = td : position = 201.6875 : offset() = 1948.6875
			// 6 : pa().pa() = tr : position = 201.6875 : offset() = 1948.6875
			// 6 : pa().pa().pa() = tbody : position = 32.7815 : offset() = 1779.78125
			// 6 : pa().pa().pa().pa() = table : position = 0 : offset() = 1747
			// 6 : pa().pa().pa().pa().pa() = div : position = 770 : offset() = 1741

			// Compare = segment ids 271, 216

			// Target = ~106
			// 5 : = a : position() = 8 : offset() = 353.7815
			// 5 : pa() = td : position() = 32.78125 : offset() = 344.78125
			// 5 : pa().pa() = tr : position() = 32.78125 : offset() = 344.78125
			// 5 : pa().pa().pa() = tbody : position() = 32.78125 : offset() = 344.78125
			// 5 : pa().pa().pa().pa() = table : position() = 0 : offset() = 312
			// 5 : pa().pa().pa().pa().pa() = div : position() = 33 : offset() = 306

			// Target = ~140
			// 17 : = a : position() = 8 : offset() = 387.5625
			// 17 : pa() = td : position() = 66.5625 : offset() = 378.5625
			// 17 : pa().pa() = tr : position() = 66.5625 : offset() = 378.5625
			// 17 : pa().pa().pa() = tbody : position() = 32.78125 : offset() = 344.78125
			// 17 : pa().pa().pa().pa() = table : position() = 0 : offset() = 312
			// 17 : pa().pa().pa().pa().pa() = div : position() = 33 : offset() = 306


			// Current sotw = segment ids 271, 1313

			// Target = ~106
			// 271 : = a : position() = 8 : offset() = 389.7815
			// 271 : pa() = td : position() = 32.78125 : offset() = 380.78125
			// 271 : pa().pa() = tr : position() = 32.78125 : offset() = 380.78125
			// 271 : pa().pa().pa() = tbody : position() = 32.78125 : offset() = 380.78125
			// 271 : pa().pa().pa().pa() = table : position() = 0 : offset() = 348
			// 271 : pa().pa().pa().pa().pa() = div : position() = 33 : offset() = 342

			// Target = ~140
  		// 1313 : = a : position() = 8 : offset() = 423.5625
			// 1313 : pa() = td : position() = 66.5625 : offset() = 414.5625
			// 1313 : pa().pa() = tr : position() = 66.5625 : offset() = 414.5625
			// 1313 : pa().pa().pa() = tbody : position() = 32.78125 : offset() = 380.78125
			// 1313 : pa().pa().pa().pa() = table : position() = 0 : offset() = 348
			// 1313 : pa().pa().pa().pa().pa() = div : position() = 33 : offset() = 342


			// Old sotw = segment ids 267, 51

			// Target = ~106
			// 267 : = a : position() = 8 : offset() = 665.90625
			// 267 : pa() = td : position() = 32.78125 : offset() = 656.90625
			// 267 : pa().pa() = tr : position() = 32.78125 : offset() = 656.90625
			// 267 : pa().pa().pa() = tbody : position() = 32.78125 : offset() = 656.90625
			// 267 : pa().pa().pa().pa() = table : position() = 0 : offset() = 624.125
			// 267 : pa().pa().pa().pa().pa() = div : position() = 33 : offset() = 618.125

			// Target = ~140
			// 51 : = a : position() = 8 : offset() = 699.6875
			// 51 : pa() = td : position() = 66.5625 : offset() = 690.6875
			// 51 : pa().pa() = tr : position() = 66.5625 : offset() = 690.6875
			// 51 : pa().pa().pa() = tbody : position() = 32.78125 : offset() = 656.90625
			// 51 : pa().pa().pa().pa() = table : position() = 0 : offset() = 624.125
			// 51 : pa().pa().pa().pa().pa() = div : position() = 33 : offset() = 618.125

			let top1 = $(this).parent().parent().parent().parent().parent().position().top; // div on page
			let top2 = $(this).parent().parent().parent().parent().position().top; // table in div
			let top3 = $(this).parent().parent().parent().position().top; // tr in table
			let top4 = $(this).parent().parent().position().top; // td in tr
			let top5 = $(this).position().top; // height of the row, so we position below it.

			let top = top1 + top2 + top3 + top4 + top5;

			let segment_id = $(this).attr( "data-segment-id" );

			$( ".segment-panel" ).hide();

			if ( segment_id != $( segment_list_class.segment_details_panel_dom_id ).attr( "data-segment-id" ) ) {
				$( segment_list_class.segment_details_panel_dom_id ).attr( "data-segment-id", segment_id );
				$( segment_list_class.segment_details_panel_dom_id ).css( { "top": ( top + "px" ) } );
				$( segment_list_class.segment_details_panel_dom_id ).show( 250 );

				jQuery.ajax({
					method: "GET",
					url: "/segment/segment_data/" + segment_id
				}).done( function( data ) {
					$( segment_list_class.segment_details_panel_dom_id ).html( data );
				});
			} else {
				$( segment_list_class.segment_details_panel_dom_id ).attr( "data-segment-id", 0 );
			}
		});
	}

} // end class