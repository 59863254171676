$(function() {
  // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    $("body .sidebar").toggle();
  });

	function sortOutSidebar() {
		if ( $(window).width() <= 1200 ) {
			$("body .sidebar").hide();
		} else {
			$("body .sidebar").show();
		}
	}

  $(window).resize(function() {
		sortOutSidebar();
	});
	
	sortOutSidebar();
});
